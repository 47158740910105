import HeroImg from "../../../assets/images/hero.png";
import Mycv from "../../../assets/tarek_chafroud.pdf";
const Hero = () => {
  return (
    <section className="hero_container">
      <div className="left_part" data-aos="fade-up">
        <h3>
          Tarek is a <span>Full stack developer</span> and a{" "}
          <span>Content creator</span>
        </h3>
        <p>I craft responsive websites where technology meets creativity.</p>
        <a
          className="dow_button"
          href={Mycv}
          download="cvtarekchafroud.pdf"
          style={{ cursor: "pointer" }}
        >
          Download my cv
        </a>
      </div>
      <div className="hero_img_container" data-aos="fade-down">
        <img src={HeroImg} alt="heroimg" />
        <span className="currentwork">
          <div></div>
          Follow my blogs posts on{" "}
          <a
            href="https://dev.to/chafroudtarek"
            style={{ cursor: "pointer", fontWeight: "bold" }}
          >
            Dev.to.
          </a>
        </span>
      </div>
    </section>
  );
};

export default Hero;
