import Avatar from "../../../assets/images/astroleft.png";
const Main = () => {
  return (
    <section className="main_container" data-aos="fade-up">
      <div className="extra-header">
        <div className="info-header">
          <div className="title">
            <span className="hashtag">#</span>
            About-me
          </div>
          <div className="line"></div>
        </div>
        <span className="subheader">Who am i?</span>
      </div>

      <div className="main_container_info">
        <div
          className="leftSide"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
        >
          <p>Hello, i’m Tarek!</p>
          <p>
            I am a skilled full-stack developer based in Sousse, Tunisia,
            specializing in React, Next.js, and a diverse array of modern
            technologies such as Node.js, Express.js, NestJS, and Angular. With
            a solid foundation in both front-end and back-end development, I
            excel in creating responsive web applications that prioritize
            seamless user experiences. My expertise lies in translating
            imaginative concepts into practical websites, integrating robust
            back-end functionalities with intuitive front-end designs. By
            staying abreast of the latest technologies and frameworks, I ensure
            my solutions are always cutting-edge and optimized for performance.
          </p>
          <p>
            My commitment to excellence extends to collaborating with clients
            and contributing to their online presence. Whether it's coding,
            problem-solving, or learning new technologies, I am always eager to
            tackle challenges and bring innovation to web development.
          </p>
        </div>
        <div
          className="rightSide"
          data-aos="fade-up"
          data-aos-anchor-placement="center-bottom"
        >
          <img
            className="astro-about"
            src={Avatar}
            alt="hero"
            style={{ marginTop: "20px" }}
          />
        </div>
      </div>
    </section>
  );
};

export default Main;
