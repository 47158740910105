import React from "react";
import AnonymFront from "../../../assets/images/astro.png";
import AnonymBack from "../../../assets/images/astroback.png";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../hooks/useWindowSize";
const Aboutme = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  return (
    <div className="aboutme_container" data-aos="fade-up">
      <div className="aboutme-header">
        <div className="title">
          <span className="hashtag">#</span>
          about-me
        </div>
        <div className="line"></div>
      </div>

      <div className="main_part">
        <div
          className="description"
          data-aos="fade-up"
          data-aos-anchor-placement="bottom-bottom"
        >
          <p>Hello, i’m Tarek!</p>
          <p>
            I'm a versatile fullstack developer located in Sousse, Tunisia,
            dedicated to building dynamic and intuitive web applications. With a
            proven track record, I specialize in seamlessly integrating frontend
            designs with backend functionalities to deliver comprehensive
            digital solutions.
          </p>
          <p>
            My approach blends creative vision with technical precision,
            ensuring that each project not only meets but exceeds expectations.
            I thrive on leveraging the latest frameworks and technologies to
            create robust, scalable applications that drive user engagement and
            business growth.
          </p>
          <div onClick={() => navigate("/about")} style={{ cursor: "pointer" }}>
            {"Read more ->"}
          </div>
        </div>
        <img
          className="astro"
          style={{
            marginTop: "40px",
            maxHeight: width < 800 ? "400px" : "600px",
          }}
          src={width < 800 ? AnonymBack : AnonymFront}
          alt="anonym"
          data-aos="fade-up"
          data-aos-anchor-placement="top-center"
        />
      </div>
    </div>
  );
};

export default Aboutme;
